import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import * as storage from '../../utils/storage';
import { Logger } from '../../utils';
import { AuthContext, AuthContextType } from './AuthContext';
import { fetchSelf as fetchSelfApi } from '~/generated/clients/private/self/PrivateSelf.client';
import { Self } from '~/generated/models/Self';
import { UserStatus } from '~/generated/models/UserStatus';

const logger = Logger('AuthHook');

type AccessToken = {
  aud: string[];
  azp: string;
  exp: number;
  iat: number;
  iss: string;
  scope: string;
  sub: string;
};

type Prop = {
  children: ReactNode;
};

export function AuthHook({ children }: Prop) {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout: authLogout,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0();

  const [init, setInit] = useState(true);
  const [self, setSelf] = useState<Self | null>(null);
  const [fetchingSelf, setFetchingSelf] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);

  const [isFetchingToken, setIsFetchingToken] = useState(false);
  const isFetching = isLoading || isFetchingToken;
  const requiresSignup =
    Boolean(self) &&
    (self?.user.status === UserStatus.Signup || !self?.user.verified);

  const logout = (states?: Record<string, unknown>) => {
    return authLogout({
      logoutParams: {
        returnTo: window.location.origin,
        ...states,
      },
    });
  };

  const login = (states?: Record<string, unknown>) => {
    loginWithRedirect({
      appState: {
        returnTo: window.location.href,
        ...states,
      },
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    });
  };

  const hasFlag = (flag: string) => {
    return self?.features.includes(flag) || false;
  };

  const hasRole = (role: string) => {
    return self?.rbac.includes(role) || false;
  };

  const getAccessToken = async (refresh = false) => {
    try {
      setIsFetchingToken(true);

      const options: GetTokenSilentlyOptions = {};
      if (refresh) {
        options.cacheMode = 'off';
      }
      logger.debug('Fetching access token with options', options);

      const accessToken = await getAccessTokenSilently(options);
      if (accessToken) {
        setToken(accessToken);
        storage.lastLogged.set(new Date());
        storage.accessToken.set(accessToken);

        const decoded = jwtDecode(accessToken) as AccessToken;
        const refreshTimeSec = decoded.exp - Date.now() / 1000 - 300;
        logger.debug('Setting refresh token timeout in', refreshTimeSec, 'sec');
        setTimeout(() => {
          getAccessToken(true);
        }, refreshTimeSec * 1000);
      }
    } catch (e) {
      logger.error(e);
      await logout();
      storage.accessToken.clear();
    }

    setIsFetchingToken(false);
  };

  const fetchSelf = async (verify = false) => {
    if (!self) {
      setFetchingSelf(true);
    }

    try {
      verify = typeof verify === 'boolean' ? verify : false;
      const result = await fetchSelfApi({
        filters: { verify },
        cacheCallback: (args) => {
          // requesting to do a verification
          // so disable cache
          if (args?.filters?.verify) {
            return {
              key: undefined,
            };
          }

          return {
            key: 'fetchSelf',
            ttl: 10000,
          };
        },
      });
      if (result.succeeded) {
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        window.__self = {
          userId: result.payload.user.id.getValue(),
          hostId: result.payload.host?.id?.getValue(),
        };
        setSelf(result.payload);
      } else if (result.status === 401 || result.status === 403) {
        await logout();
        storage.accessToken.clear();
      }
    } catch (e) {
      await logout();
      storage.accessToken.clear();
    } finally {
      setFetchingSelf(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      // If we are not authenticated and we are not fetching, we are done
      if (!isFetching) {
        setInit(false);
      }

      storage.accessToken.clear();
      return;
    }

    if (isAuthenticated && !isFetching && token) {
      fetchSelf().finally(() => setInit(false));
    }

    if (isFetching || token) {
      return;
    }

    getAccessToken();
  }, [isAuthenticated, isFetching, token]);

  const context = useMemo<AuthContextType>(
    () => ({
      self,
      isHost: Boolean(self?.host),
      requiresSignup,
      fetchSelf,
      isLogged: self !== null,
      fetchingSelf,
      isAuthenticated,
      login,
      logout,
      isLogging: isLoading,
      hasFlag,
      hasRole,
    }),
    [self, fetchingSelf, isFetching, loginWithRedirect, logout],
  );

  return (
    <AuthContext.Provider value={context}>
      {!init && children}
    </AuthContext.Provider>
  );
}
