import { dater } from '@cg/common/src/utils/DateUtils';

const PREFIX = '@@cg-token-management@@';
const ACCESS_TOKEN = `${PREFIX}/access-token`;
const LAST_LOGGED = `${PREFIX}/last-logged`;

const lastLoggedStorage = () => {
  const obj = {
    get: () => {
      return localStorage.getItem(LAST_LOGGED);
    },
    set: (date: Date) => {
      localStorage.setItem(LAST_LOGGED, date.toUTCString());
    },
    isToday: () => {
      const lastLogged = obj.get();
      if (!lastLogged) {
        return false;
      }

      return dater(lastLogged).isToday();
    },
  };

  return obj;
};
export const lastLogged = lastLoggedStorage();

const accessTokenStorage = () => {
  const obj = {
    get: () => {
      return localStorage.getItem(ACCESS_TOKEN);
    },
    set: (token: string) => {
      localStorage.setItem(ACCESS_TOKEN, token);
    },
    clear: () => {
      localStorage.setItem(ACCESS_TOKEN, '');
    },
    has: () => {
      return false;
    },
  };

  obj.has = () => {
    return obj.get() !== null && obj.get() !== '';
  };

  return obj;
};
export const accessToken = accessTokenStorage();

export const hasAccessToken = () => {
  return accessToken.has();
};
