import posthog from 'posthog-js';
import { PostHogProvider as PHProvider } from 'posthog-js/react';
import { boxConfig } from '../config';

if (!boxConfig.isLocal) {
  posthog.init('phc_Ih6HGKG8xiVO7cHAgXNxigxMVPFZac4WJwmo3FcIzTY', {
    api_host: 'https://us.i.posthog.com',
    capture_pageview: false,
  });
}

type PostHogProviderProps = {
  children: React.ReactNode;
};

export default function PostHogProvider({ children }: PostHogProviderProps) {
  return <PHProvider client={posthog}>{children}</PHProvider>;
}
