import { Navigate, createBrowserRouter } from 'react-router-dom';
import { lazy } from 'react';

import { CGRouteObject } from '@cg/module-frontend/src/router';

import { Unauthorized } from '@cg/module-frontend/src/errors';
import { ExperienceId, UserId } from '@cg/common/src/ids';
import { Suspense } from '@cg/module-frontend/src/components';
import { fetchSelf } from '~/generated/clients/private/self/PrivateSelf.client';
import { fetchExperience } from '~/generated/clients/playground/hosts/experiences/PlaygroundHostsExperiences.client.ts';
import { Experience } from '~/generated/models/Experience.ts';
import { HostGuest } from '~/generated/models/HostGuest.ts';
import { fetchGuest } from '~/generated/clients/playground/hosts/guests/PlaygroundHostsGuests.client.ts';

const HomePage = lazy(() => import('~/pages/home'));
const DashboardPage = lazy(() => import('~/pages/dashboard'));
const DashboardLayout = lazy(() => import('~/app/layout/dashboard'));
const MainLayout = lazy(() => import('~/app/layout/main'));
const EditorLayout = lazy(() => import('~/app/layout/editor'));
const ExperiencesPage = lazy(() => import('~/pages/dashboard/experiences'));
const ExperiencePage = lazy(
  () => import('~/pages/dashboard/experiences/experience'),
);
const ExperienceEditPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/edit'),
);
const OrganizersPage = lazy(
  () => import('~/pages/dashboard/settings/organizers'),
);
const ExperienceEditorSettingsPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/edit/settings'),
);
const ExperienceEditorTicketsPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/edit/tickets'),
);
const ExperienceEditorQuestionPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/edit/questions'),
);
const ProfilePage = lazy(() => import('~/pages/dashboard/profile'));
const GuestsPage = lazy(() => import('~/pages/dashboard/guests'));
const GuestPage = lazy(() => import('~/pages/dashboard/guests/guest'));
const PaymentsPage = lazy(() => import('~/pages/dashboard/settings/payments'));
const DeveloperPage = lazy(
  () => import('~/pages/dashboard/settings/developer'),
);
const CheckInPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/check-in'),
);
const SalesPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/sales'),
);
const ContactGuestsPage = lazy(
  () => import('~/pages/dashboard/experiences/experience/contact'),
);

const MainRoutes: CGRouteObject[] = [
  {
    path: '/',
    element: (
      <Suspense>
        <MainLayout />
      </Suspense>
    ),
    id: 'home',
    children: [
      {
        path: '/',
        element: (
          <Suspense>
            <HomePage />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/experience/:experienceId',
        element: (
          <Suspense>
            <ExperiencePage />
          </Suspense>
        ),
      },
    ],
  },
];

const DashboardRoutes: CGRouteObject[] = [
  {
    path: '/dashboard',
    element: (
      <Suspense>
        <DashboardLayout />
      </Suspense>
    ),
    id: 'root',
    handle: {
      requiresAuth: false,
      crumb: () => ({
        title: 'Dashboard',
        key: 'dashboard',
      }),
    },
    children: [
      {
        index: true,
        element: <Navigate to="home" replace />,
      },
      {
        path: '/dashboard/home',
        id: 'dashboard',
        element: (
          <Suspense>
            <DashboardPage />
          </Suspense>
        ),
        handle: {
          crumb: () => ({
            title: 'Home',
            key: 'home',
          }),
        },
      },
      {
        path: '/dashboard/guests',
        id: 'guests',
        handle: {
          crumb: () => ({
            title: 'Guests',
            key: 'guests',
          }),
        },
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <GuestsPage />
              </Suspense>
            ),
          },
          {
            path: '/dashboard/guests/:userId',
            children: [],
            id: 'guest',
            loader: async ({ params }) => {
              const self = await fetchSelf({});
              if (!self.succeeded || !self.payload.host) {
                throw new Unauthorized(
                  'You are not authorized to view this page',
                );
              }
              const guest = await fetchGuest({
                ids: {
                  userId: new UserId(params.userId),
                  hostId: self.payload.host.id,
                },
              });

              return guest.payload;
            },
            handle: {
              crumb: (guest: HostGuest) => ({
                title: `${guest.firstName} ${guest.lastName}`,
                key: guest.id.getValue(),
              }),
            },
            element: (
              <Suspense>
                <GuestPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: '/dashboard/profile',
        id: 'profile',
        element: (
          <Suspense>
            <ProfilePage />
          </Suspense>
        ),
        handle: {
          crumb: () => ({
            title: 'Profile',
            key: 'profile',
          }),
        },
      },
      {
        path: '/dashboard/settings',
        id: 'settings',
        handle: {
          crumb: () => ({
            title: 'Settings',
            key: 'settings',
          }),
        },
        children: [
          {
            index: true,
            element: <Navigate to="/dashboard/settings/payments" replace />,
          },
          {
            path: '/dashboard/settings/payments',
            id: 'payments',
            element: (
              <Suspense>
                <PaymentsPage />
              </Suspense>
            ),
            handle: {
              crumb: () => ({
                title: 'Payments',
                key: 'payments',
              }),
            },
          },
          {
            path: '/dashboard/settings/organizers',
            id: 'organizers',
            element: (
              <Suspense>
                <OrganizersPage />
              </Suspense>
            ),
            handle: {
              crumb: () => ({
                title: 'Users',
                key: 'users',
              }),
            },
          },
          {
            path: '/dashboard/settings/developer',
            id: 'developer',
            element: (
              <Suspense>
                <DeveloperPage />
              </Suspense>
            ),
            handle: {
              crumb: () => ({
                title: 'Developer Settings',
                key: 'developer',
              }),
            },
          },
        ],
      },
      {
        path: '/dashboard/experiences',
        id: 'experiences',
        handle: {
          crumb: () => ({
            title: 'Experiences',
            key: 'experiences',
          }),
        },
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <ExperiencesPage />
              </Suspense>
            ),
          },
          {
            path: '/dashboard/experiences/:experienceId',
            id: 'experience',
            loader: async ({ params }) => {
              const self = await fetchSelf({});
              if (!self.succeeded || !self.payload.host) {
                throw new Unauthorized(
                  'You are not authorized to view this page',
                );
              }
              const experience = await fetchExperience({
                ids: {
                  experienceId: new ExperienceId(params.experienceId),
                  hostId: self.payload.host.id,
                },
              });

              return experience.payload;
            },
            handle: {
              crumb: (experience: Experience) => ({
                title: experience.title,
                key: experience.id.getValue(),
              }),
            },
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ExperiencePage />
                  </Suspense>
                ),
              },
              {
                path: '/dashboard/experiences/:experienceId/check-in',
                id: 'experience/check-in',
                element: (
                  <Suspense>
                    <CheckInPage />
                  </Suspense>
                ),
              },
              {
                path: '/dashboard/experiences/:experienceId/sales',
                id: 'experience/sales',
                element: (
                  <Suspense>
                    <SalesPage />
                  </Suspense>
                ),
              },
              {
                path: '/dashboard/experiences/:experienceId/contact',
                id: 'experience/contact',
                element: (
                  <Suspense>
                    <ContactGuestsPage />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
];

const NonLayoutRoutes: CGRouteObject[] = [
  {
    path: '/dashboard/experiences/:experienceId/edit',
    id: 'edit',
    element: (
      <Suspense>
        <EditorLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense>
            <ExperienceEditPage />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/experiences/:experienceId/edit/settings',
        id: 'edit/settings',
        element: (
          <Suspense>
            <ExperienceEditorSettingsPage />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/experiences/:experienceId/edit/tickets',
        id: 'edit/tickets',
        element: (
          <Suspense>
            <ExperienceEditorTicketsPage />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/experiences/:experienceId/edit/questions',
        id: 'edit/questions',
        element: (
          <Suspense>
            <ExperienceEditorQuestionPage />
          </Suspense>
        ),
      },
    ],
  },
];

const route = createBrowserRouter([
  ...MainRoutes,
  ...DashboardRoutes,
  ...NonLayoutRoutes,
]);

export default route;
