/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { HostGuest } from '../../../../models/HostGuest';
import { HostId } from '@cg/common/src/ids/HostId';
import { UserId } from '@cg/common/src/ids/UserId';
import { Page } from '@cg/common/src/paging/Page';
import { ExperienceWithTickets } from '../../../../models/ExperienceWithTickets';
import { Experience } from '../../../../models/Experience';

export type FetchGuestArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        userId: UserId,
    },
}

/**
 * @summary Returns the Host guest.
 * @param {FetchGuestArgs} args
 */
export const fetchGuest = (args: FetchGuestArgs): Promise<HttpResult<HostGuest>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/guests/{userId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListGuestExperiencesArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        userId: UserId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Returns the list of Experiences this User has gone to.
 * @param {ListGuestExperiencesArgs} args
 */
export const listGuestExperiences = (args: ListGuestExperiencesArgs): Promise<HttpResult<Page<ExperienceWithTickets>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/guests/{userId}/experiences', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListGuestsArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Returns the list of Users that belong to this Host.
 * @param {ListGuestsArgs} args
 */
export const listGuests = (args: ListGuestsArgs): Promise<HttpResult<Page<HostGuest>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/guests', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
